import { Link } from 'react-scroll'
import pagesHeadersArray from '../../datas/pagesHeadersArray.json'
import PagesHeaders from '../../components/PagesHeaders'

// styles
import './m-about.css'
import './d-about.css'

const About = () => { 
    
    const aboutHeader = pagesHeadersArray.find(el => el.title === "1") 
    const aboutHeaderArray = []
    aboutHeaderArray.push(aboutHeader)

	return (
		<section 
                id='about' 
                className='about'
        >
            {aboutHeaderArray.map((item, index) => (
                <PagesHeaders 
                    key={index}
                    titleCol1={item.titleCol1}
                    subTitle1={item.subTitle1}
                    subTitle2={item.subTitle2}
                    button={
                        <Link to='agences'>
                            <p className='button'>Suivez nos conseils</p>
                        </Link>
                    }
                    text1={
                        <div id='containerAbout' className='containerAbout'>
                            <p className='containerAbout__text' >
                                <span className='containerAbout__text__header'>
                                    Spécialiste de la construction et rénovation depuis plus de 20 ans
                                </span>
                                <br/><br/>
                                Forts de notre expérience et de notre savoir-faire, nous sommes fiers de proposer des services de qualité supérieure à nos clients.
                                <br/><br/>
                                Chez BCRA, notre équipe de maçons qualifiés est passionnée par son métier s'engage à fournir un travail d'excellence à chaque étape du projet. Que vous souhaitiez construire une nouvelle maison, agrandir votre espace de vie ou rénover votre propriété existante, nous sommes là pour concrétiser vos idées.  
                                <br/><br/>
                                Nous accordons une grande importance à la qualité de notre travail, en utilisant des matériaux de premier choix, en respectant les normes de sécurité les plus strictes et en adoptant les techniques les plus avancées.
                                <br/><br/>
                                Notre objectif est de vous offrir des résultats exceptionnels, à la hauteur de vos attentes et de vos exigences.
                                <br/><br/>
                                Nous sommes également attentifs aux délais et au respect des budgets convenus, afin de garantir une expérience sans souci pour nos clients.
                            </p>
                            <div className='containerAbout__bloc'>
                                <img 
                                    className='containerAbout__bloc__handshake' 
                                    src=  {`${process.env.PUBLIC_URL}/assets/pictures/handshake.jpg`}
                                    width='1000px'
                                    height='1500px'
                                    fetchpriority='high' 
                                    alt='serrage de main'
                                />
                                <div className='containerAbout__bloc__square'>-</div>
                                <p className='containerAbout__bloc__pub'>
                                    Notre objectif
                                    <br/>
                                    est de vous offrir des résultats exceptionnels
                                </p>
                            </div>
                        </div>
                    } 
                />
            ))}
		</section>
	)
}

export default About